<template>
  <div class="container">
    <!-- 在Element-Plus中，menu组件加上 router属性，默认以path作为路径做路由跳转 -->
    <el-menu
      :default-active="$route.path"
      router
      :collapse="isCollapse"
      :class="$store.getters.theme === 'dart' ? 'dart' : ''"
    >
      <sidebar-item v-for="item in fRouters" :key="item.path" :menuData="item">
      </sidebar-item>
    </el-menu>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { getMenuData } from '@/utils/route'
import { computed } from '@vue/runtime-core'
import SidebarItem from './SidebarItem.vue'
// import { useStore } from 'vuex'

// const store = useStore()
const fRouters = computed(() => {
  return getMenuData()
})
const isCollapse = ref(false)
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';
.container {
  .el-menu {
    border-right: none;
  }
  .dart {
    background-color: $menuBg;
    :deep .el-menu-item {
      color: $menuText;
    }
    :deep .el-menu-item.is-active {
      background-color: $menuHover;
    }
    :deep .el-menu-item:hover,
    :deep .el-sub-menu__title:hover {
      background-color: $menuHover;
      font-weight: bold;
    }
    :deep .el-sub-menu.is-opened .el-menu {
      background-color: $menuBg;
      .el-menu-item.is-active {
        background-color: $menuHover;
      }
    }

    :deep .el-sub-menu__title {
      color: white;
    }
  }
}
</style>
