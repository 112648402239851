import layout from '@/layout'
export default {
  path: '/admin',
  component: layout,
  name: 'admin',
  meta: {
    title: 'admin',
    icon: 'admin'
  },
  children: [
    {
      path: '/admin/list',
      component: () => import('@/views/admin/index'),
      name: '管理员列表',
      meta: {
        title: 'adminList',
        icon: 'admin-list'
      }
    }
  ]
}
