import layout from '@/layout'

export default {
  path: '/setting',
  component: layout,
  redirect: '/setting/vip',
  name: 'setting',
  meta: {
    title: 'setting',
    icon: 'setting'
  },
  children: [
    {
      path: '/settting/vip',
      component: () => import('@/views/setting/vip'),
      name: 'VIP设置',
      meta: {
        title: 'vipSetting',
        icon: 'vip'
      }
    }
  ]
}
