<template>
  <div class="container">
    <el-tooltip :content="$t('msg.guide.tips')">
      <svg-icon
        icon="guide"
        class="svg-icon"
        id="guide-start"
        @click="onStartGuide"
      />
    </el-tooltip>
  </div>
</template>

<script setup>
import Driver from 'driver.js'
import 'driver.js/dist/driver.min.css'
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import steps from './steps'

const i18n = useI18n()

let driver = null
onMounted(() => {
  driver = new Driver({
    allowClose: false,
    closeBtnText: i18n.t('msg.guide.close'),
    nextBtnText: i18n.t('msg.guide.next'),
    prevBtnText: i18n.t('msg.guide.prev'),
    doneBtnText: i18n.t('msg.guide.done')
  })
})
// 启动引导
const onStartGuide = () => {
  driver.defineSteps(steps(i18n))
  driver.start()
}
</script>

<style lang="scss" scoped>
.svg-icon {
  width: 24px;
  height: 24px;
}
</style>
