import layout from '@/layout'
export default {
  path: '/project',
  component: layout,
  name: 'project',
  meta: {
    title: 'project',
    icon: 'project'
  },
  children: [
    {
      path: '/project/category',
      component: () => import('@/views/project/category/index'),
      name: '项目分类',
      meta: {
        title: 'projectCategory',
        icon: 'category'
      }
    },

    {
      path: '/project/list',
      component: () => import('@/views/project/index'),
      name: '项目课程',
      meta: {
        title: 'projectList',
        icon: 'course-list'
      }
    },
    {
      path: '/project/create',
      component: () => import('@/views/project/create'),
      name: '添加项目课程',
      meta: {
        title: 'projectCreate'
      }
    },
    {
      path: '/project/edit',
      component: () => import('@/views/project/edit'),
      name: '编辑项目课程',
      meta: {
        title: 'projectEdit'
      }
    },

    {
      path: '/project/chapter/list',
      component: () => import('@/views/project/chapter/index'),
      name: '项目章节列表',
      meta: {
        title: 'chapterList'
      }
    },
    {
      path: '/project/document/list',
      component: () => import('@/views/project/document/index'),
      name: '项目课时列表',
      meta: {
        title: 'projectCourseList'
      }
    },
    {
      path: '/systemcourse',
      name: '体系课',
      component: () => import('@/views/systempath/index'),
      meta: {
        title: 'systemcourse',
        icon: 'systemcourse'
      }
    },
    {
      path: '/systemcourse/stage/:id',
      name: '体系课阶段',
      component: () => import('@/views/systempath/stage'),
      meta: {
        title: 'systemPathStage'
      }
    },
    {
      path: '/systemcourse/list',
      name: '体系课项目',
      component: () => import('@/views/systempath/list'),
      meta: {
        title: 'systemPathList',
        icon: 'systemPathList'
      }
    }
  ]
}
