/*
 * @Author: 螺钉大叔
 * @Date: 2022-04-02 08:47:56
 * @LastEditors: 螺钉大叔
 * @LastEditTime: 2022-04-02 12:45:28
 * @Description: 封装请求工具
 */
import axios from 'axios'
import store from '@/store'
import router from '@/router'
import { ElMessage } from 'element-plus'

const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000
})

// 请求拦截器
request.interceptors.request.use((config) => {
  // 判断token是否存在
  if (store.getters.token) {
    // 如果token存在 请求的时候带上token
    config.headers.token = store.getters.token
  }
  // 必须返回 config
  return config
})

// 响应拦截
request.interceptors.response.use(
  (res) => {
    const { status, data } = res
    if (status === 200) {
      return Promise.resolve(data)
    }
  },
  (err) => {
    // 判断token是否过期
    const { status, data } = err.response
    if (status == 400) {
      ElMessage.error(data.error)
    }
    if (status == 401) {
      ElMessage.error('token已经过期')
      store.dispatch('sys/logout')
    }
    if (status == 403) {
      ElMessage.error('没有权限，禁止访问')
      store.dispatch('sys/logout')
    }
    if (status == 404) {
      ElMessage.error('页面没有找到')
      router.push('/login')
    }
    return Promise.reject(err.response)
  }
)

export default request
