export default {
  namespaced: true,
  state: () => ({
    routes: []
  }),
  mutations: {
    setRouts(state, newRoutes) {
      state.routes = [...newRoutes]
    }
  }
}
