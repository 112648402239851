// 解析路由表
import store from '@/store'
import { toRaw } from 'vue'
export const getMenuData = () => {
  const allRoutes = toRaw(store.getters.routes)
  // 根据解析的路由表，获取到有子路由的路由数据
  const allChildrenRoute = getChildrenRoute(allRoutes)

  // 过滤子路由
  const result = allRoutes.filter((item) => {
    return !allChildrenRoute.find((childRoute) => {
      return childRoute.path == item.path
    })
  })
  // 将数据处理成树型结构数据
  const jsonData = getJsonData(result)
  return jsonData
}

// 递归生成树形结构数据
const getJsonData = (arr) => {
  const menuArr = []
  arr.forEach((item) => {
    let obj = {
      path: '',
      meta: {},
      children: []
    }
    // 如果是meta为空，表示不渲染 直接返回，我们约定，meta.title&&meta.icon都同时存在才渲染到左侧菜单
    if (isEmpty(item.meta) || !item.meta.title || !item.meta.icon) return
    obj.path = item.path
    obj.meta = item.meta
    // 如果有子路由
    if (!isEmpty(item.children)) {
      // 递归调用，把返回值放到obj.children字段上
      obj.children = getJsonData(item.children)
    }
    menuArr.push(obj)
  })
  return menuArr
}
// 判断是否为空
const isEmpty = (data) => {
  if (!data) return true
  if (Array.isArray(data) && data.length === 0) return true
  if (
    Object.prototype.toString.call(data) === '[object Object]' &&
    Object.keys(data).length === 0
  )
    return true
  return false
}

// 获取含有子路由的路由数据表
const getChildrenRoute = (routes) => {
  const arr = []
  routes.forEach((item) => {
    if (item.children && item.children.length > 0) {
      arr.push(...item.children)
    }
  })
  return arr
}
