import layout from '@/layout'
export default {
  path: '/article',
  component: layout,
  name: 'article',
  meta: {
    title: 'article',
    icon: 'document'
  },
  children: [
    {
      path: '/article/list',
      component: () => import('@/views/article/index'),
      name: '文章列表',
      meta: {
        title: 'articleList',
        icon: 'article-list'
      }
    },
    {
      path: '/article/category',
      component: () => import('@/views/article/category'),
      name: '文章分类',
      meta: {
        title: 'articleCategory',
        icon: 'category'
      }
    },
    {
      path: '/article/create',
      name: '创建文章',
      component: () => import('@/views/article/create'),
      meta: {
        title: 'articleCreate',
        icon: 'create-article'
      }
    },
    {
      path: '/article/edit/:id',
      name: '编辑文章',
      component: () => import('@/views/article/edit'),
      meta: {
        title: 'articleEditor'
      }
    }
  ]
}
