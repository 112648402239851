import article from './module/article'
import permission from './module/permission'
import admin from './module/admin'
import course from './module/course'
import user from './module/user'
import setting from './module/setting'
import project from './module/project'

// 配置私有路由，即需要具有权限才可以访问  路由里面配置了：title和icon，说明要显示在左侧菜单
export const privateRoutes = [
  course,
  project,
  article,
  user,
  permission,
  admin,
  setting
]
