import router from '@/router'
import store from '@/store'
import { ElMessage } from 'element-plus'
// import { toRaw } from 'vue'
import { privateRoutes } from './router/privateRoutes'
import { publicRoutes } from './router/publicRoutes'

// 定义一个白名单地址，可以在没有token的时候访问
const whiteList = ['/login']
router.beforeEach(async (to, from, next) => {
  // 先判断是不是访问的白名单地址, 如果是进入到这里
  if (whiteList.indexOf(to.path) > -1) {
    // 判断有没有token
    if (store.getters.token) {
      //如果有token就进入到首页
      next('/')
    } else {
      //没有token就进入到login登录页面 目前白名单只有 login页面
      next()
    }
  } else {
    // 不是白名单地址 进入到这里,判断是否有token
    if (!store.getters.token) {
      // 如果没有token，说明没有权限进入
      ElMessage.error('没有权限')
      // 跳转到登录页面
      next('/login')
    } else {
      // 判断是否有管理员的信息，如果没有就要重新获取
      if (!store.getters.hasUserInfo) {
        await store.dispatch('sys/getAdminInfo')
        const routes = []
        const menus = store.getters.userInfo.permission.menu
        // 需要做深拷贝，否则会相互影响
        const tempRoutes = privateRoutes.map((item) => ({ ...item }))
        tempRoutes.forEach((module) => {
          // 权限名 与 路由的 name 匹配
          module.children = module.children.filter((item) =>
            menus.includes(item.name)
          )
          if (module.children.length !== 0) {
            routes.push(module)
          }
        })

        // 最后添加 不匹配路由进入 404
        routes.push({
          path: '/:catchAll(.*)',
          redirect: '/404'
        })
        routes.forEach((item) => {
          router.addRoute(item)
        })

        store.commit('permission/setRouts', [...publicRoutes, ...routes])
        next({ ...to, replace: true })
      } else {
        next()
      }
    }
  }
})
