<template>
  <div class="container">
    <el-dropdown @command="handleSetLanguage">
      <div>
        <svg-icon icon="language" id="guide-lang" />
      </div>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item :disabled="language === 'zh'" command="zh"
            >中文</el-dropdown-item
          >
          <el-dropdown-item :disabled="language === 'en'" command="en"
            >English</el-dropdown-item
          >
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
const store = useStore()
const language = computed(() => store.getters.language)
const i18n = useI18n()
const handleSetLanguage = (lang) => {
  i18n.locale.value = lang
  store.commit('app/setLanguage', lang)
  ElMessage({
    message: '语言切换成功',
    duration: 1000,
    type: 'success'
  })
}
</script>

<style lang="scss" scoped>
.container {
  margin-right: 10px;
}
</style>
