import { login, getAdminInfo } from '@/api/system'
import storage from '@/utils/storage'
import { TOKEN } from '@/config'

import router from '@/router'
export default {
  namespaced: true,
  state: () => ({
    token: storage.getItem(TOKEN) || '',
    userInfo: {}
  }),
  mutations: {
    setToken(state, token) {
      state.token = token
      storage.setItem(TOKEN, token)
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    }
  },
  actions: {
    login({ commit }, userInfo) {
      const { mobile, password, code } = userInfo
      return new Promise((resolve, reject) => {
        login({
          mobile,
          password,
          code
        })
          .then((result) => {
            resolve(result)
            commit('setToken', result.data.token)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    async getAdminInfo({ commit }) {
      const res = await getAdminInfo()
      commit('setUserInfo', res.data)
      return res
    },
    logout({ commit }) {
      commit('setToken', '')
      commit('setUserInfo', {})
      this.commit('app/initTagsList')
      storage.clearAll()
      router.push('/login')
    }
  }
}
