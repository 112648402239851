export default {
  login: {
    logo: 'nodeing.com',
    title: 'User Login',
    userLabel: 'username',
    passLabel: 'password',
    loginBtn: 'submit',
    resetBtn: 'reset',
    userRule: 'username is required',
    passRule: {
      req: 'password is required',
      len: 'password length 6-16 characters'
    }
  },
  admin: {
    username: 'Username',
    mobile: 'Mobile',
    role: 'Please Select Role',
    password: 'Password'
  },
  route: {
    home: 'Home',
    profile: 'Profile',
    user: 'User',
    admin: 'Admin',
    excelImport: 'ExcelImport',
    userList: 'UserList',
    adminList: 'AdminList',
    permission: 'Permission',
    persisionList: 'PermissionList',
    userInfo: 'UserInfo',
    roleList: 'RoleList',
    permissionList: 'PermissionList',
    article: 'Article',
    articleList: 'ArticleList',
    articleCreate: 'ArticleCreate',
    articleDetail: 'ArticleDetail',
    articleEditor: 'ArticleEditor',
    articleCategory: 'ArticleCategory',
    company: 'Company',
    companyList: 'CompanyList',
    setting: 'Setting',
    settingWebsite: 'SettingWebsite'
  },
  contextMenu: {
    refresh: 'refresh',
    closeRight: 'closeRight',
    closeOther: 'closeOther'
  },
  guide: {
    tips: 'Tips',
    close: 'Close',
    next: 'Next',
    prev: 'Prev',
    done: 'Done',
    guideTitle: 'Guidance',
    guideDesc: 'Turn on the boot function',
    extendTitle: 'Sidebar extend button',
    extendDesc: 'Open and close the left menu',
    breadcrumbTitle: 'Bread crumbs',
    breadcrumbDesc: 'Indicates the current page position',
    searchTitle: 'Search',
    searchDesc: 'Page link search',
    fullTitle: 'Full screen',
    fullDesc: 'Page display switching',
    themeTitle: 'theme',
    themeDesc: 'Change project theme',
    langTitle: 'Globalization',
    langDesc: 'Language switch',
    tagTitle: 'Label',
    tagDesc: 'Opened page tab',
    sidebarTitle: 'Menu',
    sidebarDesc: 'Project function menu'
  },
  excel: {
    importExcel: 'excel import',
    exportExcel: 'excel export',
    exportZip: 'zip export',
    name: 'Name',
    mobile: 'contact details',
    avatar: 'Avatar',
    role: 'Role',
    openTime: 'Opening time',
    action: 'Operate',
    show: 'Check',
    showRole: 'Role',
    defaultRole: 'Staff',
    remove: 'delete',
    removeSuccess: 'Deleted successfully',
    title: 'Export to excel',
    placeholder: 'excel file name',
    defaultName: 'Staff Management Form',
    close: 'Cancel',
    confirm: 'Export',
    importSuccess: ' Employee data imported successfully',
    dialogTitle1: 'Are you sure you want to delete the user ',
    dialogTitle2: ' Is it?',
    roleDialogTitle: 'Configure roles'
  }
}
