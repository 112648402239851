const getters = {
  token: (state) => state.sys.token,
  hasUserInfo: (state) => {
    return JSON.stringify(state.sys.userInfo) !== '{}'
  },
  userInfo: (state) => state.sys.userInfo,
  isExtend: (state) => state.app.isExtend,
  language: (state) => state.app.language,
  theme: (state) => state.app.theme,
  tagsList: (state) => state.app.tagsList,
  routes: (state) => state.permission.routes
}
export default getters
