// 导入图标组件
import SvgIcon from '@/components/SvgIcon'
// https://www.webpackjs.com/guides/dependency-management/
function importAll(r) {
  r.keys().forEach(r)
}
const r = require.context('./svg', false, /\.svg$/)
importAll(r)

export default (app) => {
  app.component('svg-icon', SvgIcon)
}
