import layout from '@/layout'
export default {
  path: '/permission',
  component: layout,
  name: 'permission',
  redirect: '/permission/list',
  meta: {
    title: 'permission',
    icon: 'permission'
  },
  children: [
    {
      path: '/permission/list',
      component: () => import('@/views/permission/index'),
      name: '权限列表',
      meta: {
        title: 'permissionList',
        icon: 'permission-list'
      }
    },
    {
      path: '/role/list',
      name: '角色列表',
      component: () => import('@/views/role/index'),
      meta: {
        title: 'roleList',
        icon: 'role-list'
      }
    }
  ]
}
