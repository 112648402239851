import storage from '@/utils/storage'
import { LANG, THEME, TAGS_VIEW } from '@/config'
export default {
  namespaced: true,
  state: () => ({
    // 控制菜单伸缩状态，默认展开
    isExtend: true,
    // 初始化语言状态，如果本地没缓存，默认中文
    language: storage.getItem(LANG) || 'zh',
    // 控制主题状态
    theme: storage.getItem(THEME) || 'white',
    //标签列表
    tagsList: storage.getItem(TAGS_VIEW) || []
  }),
  mutations: {
    // 切换左侧菜单伸缩状态
    toggleExtend(state) {
      state.isExtend = !state.isExtend
    },
    // 设置语言
    setLanguage(state, lang) {
      storage.setItem(LANG, lang)
      state.language = lang
    },
    // 设置主题
    setTheme(state, theme) {
      storage.setItem(THEME, theme)
      state.theme = theme
    },
    // 初始化tag
    initTagsList(state) {
      state.tagsList = []
    },
    // 添加标签
    addTagsToList(state, tag) {
      // 先查看标签是否已经在列表里面
      const isExist = state.tagsList.find((item) => {
        return item.path === tag.path
      })
      // 如果tag不在列表中就执行添加
      if (!isExist) {
        state.tagsList.push(tag)
        storage.setItem(TAGS_VIEW, state.tagsList)
      }
    },
    // 删除标签
    removeTagsFromList(state, payload) {
      const { type, index } = payload
      switch (type) {
        case 'index':
          // 删除当前项
          state.tagsList.splice(index, 1)
          break
        case 'right':
          //删除右边tag
          state.tagsList.splice(index + 1, state.tagsList.length - index + 1)
          break
        case 'left':
          // 删除左边
          state.tagsList.splice(0, index)
          break
        case 'other':
          // 删除右边
          state.tagsList.splice(index + 1, state.tagsList.length - index + 1)
          // 删除左边
          state.tagsList.splice(0, index)
          break
      }
      storage.setItem(TAGS_VIEW, state.tagsList)
    }
  },
  actions: {}
}
