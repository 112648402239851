<template>
  <el-container>
    <el-aside
      class="app-aside"
      :class="[$store.getters.isExtend ? 'app-sidebar' : 'app-hide-sidebar']"
    >
      <div class="app-aside-container">
        <logo />
        <sidebar-menu id="guide-sidebar"></sidebar-menu>
      </div>
    </el-aside>
    <el-container class="app-main">
      <el-header class="app-header">
        <div class="header-top">
          <el-row>
            <el-col :span="18" class="header-left">
              <!-- <div class="extend-button">
                <toggle-extend></toggle-extend>
              </div> -->
              <div class="breadcrumb-box">
                <Breadcrumb id="guide-breadcrumb" />
              </div>
            </el-col>
            <el-col :span="6">
              <div class="header-right">
                <el-dropdown>
                  <span class="el-dropdown-link">
                    <!-- <el-avatar :size="30" :src="$store.getters.userInfo.avatar" /> -->
                    <el-avatar
                      :size="30"
                      src="https://nodeing-com-1252923609.cos.ap-chengdu.myqcloud.com/1654278275455.jpg"
                    />
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item>个人中心</el-dropdown-item>
                      <el-dropdown-item @click="handleLogout"
                        >退出登录</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
                <lang-select></lang-select>
                <theme-select></theme-select>
                <screenfull></screenfull>
                <header-search></header-search>
                <guide></guide>
              </div>
            </el-col>
          </el-row>
        </div>
        <tags-view id="guide-tags"></tags-view>
      </el-header>
      <el-main class="app-content">
        <router-view v-slot="{ Component, route }">
          <transition name="fade-transform" mode="out-in">
            <keep-alive>
              <component :is="Component" :key="route.path" />
            </keep-alive>
          </transition>
        </router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script setup>
import logo from './components/logo.vue'
import SidebarMenu from './components/SidebarMenu.vue'
// import ToggleExtend from '@/components/ToggleExtend/index.vue'
import Breadcrumb from '@/components/Breadcrumb/index.vue'
import LangSelect from '@/components/LangSelect/index.vue'
import ThemeSelect from '@/components/ThemeSelect/index.vue'
import Screenfull from '@/components/Screenfull/index.vue'
import HeaderSearch from '@/components/HeaderSearch/index.vue'
import TagsView from '@/components/TagsView/index.vue'
import Guide from '@/components/Guide/index.vue'
import { useStore } from 'vuex'
import { computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import i18n from '@/lang'
import { isTags } from '@/utils/tags'

const store = useStore()
const handleLogout = () => {
  store.dispatch('sys/logout')
}
const bgcolor = computed(() =>
  store.getters.theme === 'dart' ? '#000' : '#fff'
)

// 获取到title
const getTitle = (route) => {
  let title = ''
  // 如果meta不存在
  if (!route.meta) {
    // 把路径以 / 分割，取最后一个元素作为title
    const pathArr = route.path.split('/')
    title = pathArr[pathArr.length - 1]
  } else {
    title = i18n.global.t('msg.route.' + route.meta.title)
  }
  return title
}
const route = useRoute()

watch(
  route,
  (to) => {
    if (!isTags(to.path)) return
    const { fullPath, meta, name, params, path, query } = to
    store.commit('app/addTagsToList', {
      fullPath,
      meta,
      name,
      params,
      path,
      query,
      title: getTitle(to)
    })
  },
  {
    immediate: true
  }
)
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';

.app-aside {
  // height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: $sideBarWidth;
  transition: width 0.2s;
  background-color: v-bind(bgcolor);
  border-right: 1px solid #e4e7ed;
  .app-aside-container {
    top: 0;
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
  }
}
.app-main {
  margin-left: $sideBarWidth;
}
.app-hide-sidebar {
  width: $hideSideBarWidth;
  transition: width 0.2s;
}
.app-header {
  height: 100px;
  box-shadow: 0px 1px 1px #eeeeee;
  .extend-button {
    height: 50px;
    line-height: 50px;
    margin-right: 20px;
  }
  .header-top {
    height: 50px;
    margin: 0 -20px;
    padding: 0 20px;
    box-shadow: 0px 1px 1px #e4e7ed;
  }
  .header-left {
    display: flex;
    align-items: center;
  }
  .header-right {
    display: flex;
    flex-direction: row-reverse;
    margin-right: 20px;
    align-items: center;
    height: 50px;
    img {
      width: 30px;
      height: 30px;
    }
    .example-showcase .el-dropdown-link {
      cursor: pointer;
      color: var(--el-color-primary);
      display: flex;
      align-items: center;
    }
  }
}
</style>
