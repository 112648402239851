<template>
  <el-dropdown class="theme" @command="handleSetTheme">
    <div>
      <svg-icon icon="theme" class="svg-icon" id="guide-theme" />
    </div>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item command="dart"> 暗黑 </el-dropdown-item>
        <el-dropdown-item command="white"> 白色 </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script setup>
import { useStore } from 'vuex'
const store = useStore()
const handleSetTheme = (command) => {
  store.commit('app/setTheme', command)
}
</script>

<style lang="scss" scoped>
.theme {
  margin-right: 10px;
  .svg-icon {
    width: 20px;
    height: 20px;
  }
}
</style>
