<template>
  <div class="container">
    <router-link
      class="tags-item"
      :class="isActive(tag) ? 'active' : ''"
      v-for="(tag, index) in $store.getters.tagsList"
      :key="tag.fullPath"
      :to="{ path: tag.fullPath }"
      @contextmenu.prevent="showContextMenux($event, index)"
    >
      {{ tag.title }}

      <i
        v-show="!isActive(tag)"
        class="icon-close"
        @click.prevent.stop="onClose(index)"
      >
        <svg-icon icon="close"></svg-icon>
      </i>
    </router-link>
    <context-menu
      v-show="isShow"
      v-model="isShow"
      :index="nowIndex"
      :style="contextMenuStyle"
    ></context-menu>
  </div>
</template>

<script setup>
import SvgIcon from '@/components/SvgIcon'
import ContextMenu from '@/components/ContextMenu/index.vue'
import { useRoute } from 'vue-router'
import { reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
const route = useRoute()
const isActive = (tag) => {
  return tag.path === route.path
}
const nowIndex = ref(0)
const contextMenuStyle = reactive({
  left: 0,
  top: 0
})
// 控制右键菜单是否显示
const isShow = ref(false)
const showContextMenux = (event, index) => {
  nowIndex.value = index
  contextMenuStyle.left = event.x + 'px'
  contextMenuStyle.top = event.y + 20 + 'px'
  isShow.value = true
}
const store = useStore()
const onClose = (index) => {
  store.commit('app/removeTagsFromList', {
    type: 'index',
    index
  })
}
watch(isShow, (val) => {
  if (val) {
    document.body.addEventListener('click', closeContextMenu)
  } else {
    document.body.removeEventListener('click', closeContextMenu)
  }
})
const closeContextMenu = () => {
  isShow.value = false
}
</script>

<style lang="scss" scoped>
.container {
  padding: 10px 0;
  overflow: hidden;
  display: flex;
  align-items: center;

  .tags-item {
    border: 1px solid #e4e7ed;
    padding: 8px 16px;
    margin-right: 2px;
    display: inline-block;
    font-size: 14px;
    .icon-close svg {
      width: 10px;
      height: 10px;
    }
  }
  .tags-item.active {
    background-color: #000;
    color: white;
    &::before {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #fff;
      display: inline-block;
      margin-right: 4px;
    }
  }
}
</style>
