import request from '@/utils/request'

// 登录的请求接口
export const login = (data) => {
  return request({
    url: '/admin/login',
    method: 'POST',
    data
  })
}

export const getAdminInfo = () => {
  return request({
    url: '/admin/info',
    method: 'GET'
  })
}
