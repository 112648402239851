import { createApp } from 'vue'
// 引入通用的样式重置css
import './assets/css/reset.css'

// 引入全局样式
import '@/styles/index.scss'

import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
import installIcons from './icons/index'
import './permission'
import * as Icons from '@element-plus/icons-vue'
import i18n from './lang'

const app = createApp(App)
installElementPlus(app)
installIcons(app)
// 注册全局组件
Object.keys(Icons).forEach((key) => {
  app.component(key, Icons[key])
})
app.use(i18n)
app.use(store).use(router).mount('#app')
