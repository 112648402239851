import zhLocale from './zh'
import enLocale from './en'
import store from '@/store'
const messages = {
  en: {
    msg: {
      ...enLocale
    }
  },
  zh: {
    msg: {
      ...zhLocale
    }
  }
}
const locale = () => {
  return store && store.getters && store.getters.language
}

import { createI18n } from 'vue-i18n'

const i18n = createI18n({
  // 使用 Composition API 模式，则需要将其设置为false
  legacy: false,
  // 全局注入 $t 函数
  globalInjection: true,
  locale: locale(),
  messages
})
export default i18n
