import layout from '@/layout'
export default {
  path: '/course',
  component: layout,
  name: 'course',
  meta: {
    title: 'course',
    icon: 'course'
  },
  children: [
    {
      path: '/course/category',
      component: () => import('@/views/course/category/index'),
      name: '课程分类',
      meta: {
        title: 'courseCategory',
        icon: 'category'
      }
    },

    {
      path: '/course/list',
      component: () => import('@/views/course/index'),
      name: '课程列表',
      meta: {
        title: 'courseList',
        icon: 'course-list'
      }
    },
    {
      path: '/course/create',
      component: () => import('@/views/course/create'),
      name: '添加视频课程',
      meta: {
        title: 'courseCreate'
      }
    },

    {
      path: '/course/chapter/list/:id',
      component: () => import('@/views/course/chapter/index'),
      name: '视频章节列表',
      meta: {
        title: 'chapterList'
      }
    },
    {
      path: '/learnpath',
      name: '学习路线',
      component: () => import('@/views/learnpath/index'),
      meta: {
        title: 'learnPath',
        icon: 'learnpath'
      }
    },
    {
      path: '/learnpath/stage/:id',
      name: '学习阶段',
      component: () => import('@/views/learnpath/stage'),
      meta: {
        title: 'learnPathStage'
      }
    },
    {
      path: '/course/book',
      name: '课程电子书',
      component: () => import('@/views/course/book/index'),
      meta: {
        title: 'courseBook',
        icon: 'book'
      }
    }
  ]
}
