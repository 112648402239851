<template>
  <div class="container">
    <svg-icon
      icon="search"
      @click.stop="showSelect"
      class="searchicon"
      id="guide-search"
    ></svg-icon>
    <!--
      select组件属性：
        filterable  组件是否可以筛选
        remote-method 自定义远程搜索方法
        default-first-option 是否在输入框按下回车时，选择第一个匹配项。 需配合 filterable 或 remote 使用
     -->
    <el-select
      filterable
      remote
      placeholder="search"
      :remote-method="remoteMethod"
      default-first-option
      class="select"
      :class="isShow ? 'showSelect' : ''"
      @change="onSelectChange"
      ref="searchRef"
      v-model="selectData"
      @blur="onSelectBlur"
    >
      <el-option
        v-for="item in queryResult"
        :key="item.item.path"
        :label="item.item.title && item.item.title.join('>')"
        :value="item.item.path"
      ></el-option>
    </el-select>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { getMenuData } from '@/utils/route'
import { getFuseData } from './datasource'
import Fuse from 'fuse.js'
import SvgIcon from '@/components/SvgIcon/index.vue'
/**
 * select相关
 */
//控制搜索框是否显示的变量
const isShow = ref(false)
// 获取到select框实例
const searchRef = ref(null)
// select绑定值
const selectData = ref([])
// 是否显示搜索框的事件处理函数
const showSelect = () => {
  // 点击后控制搜索框变量的值取反
  isShow.value = !isShow.value
  // 点击后获取焦点
  searchRef.value.focus()
  if (!isShow.value) {
    queryResult.value = ''
  }
}
// 失去焦点清空数据
const onSelectBlur = () => {
  selectData.value = []
}
/**
 * 准备模糊搜索需要的数据
 */
// 检索的数据源
const options = ref([])
const router = useRouter()
const res = getFuseData(getMenuData(router))
options.value = res

/**
 * fuse 初始化 模糊搜索
 */
const fuse = new Fuse(options.value, {
  // 按优先级排序
  shouldSort: true,
  // 匹配的最小长度，1 表示 只要匹配上1个字符就可以
  minMatchCharLength: 1,
  // 搜索的键列表
  // name：搜索的键
  // weight：对应的权重
  keys: [
    {
      name: 'title',
      weight: 0.8
    },
    {
      name: 'path',
      weight: 0.2
    }
  ]
})
/**
 * 搜索结果处理
 */
// 搜索结果
const queryResult = ref([])
// 远程获取数据的方法
const remoteMethod = (query) => {
  if (query !== '') {
    queryResult.value = fuse.search(query)
  } else {
    queryResult.value = []
  }
}

/**
 * 搜索完成后 跳转处理逻辑
 */
// 搜索框改变事件处理函数
const onSelectChange = (path) => {
  router.push(path)
}
</script>

<style lang="scss" scoped>
.container {
  // margin-right: 10px;
  display: flex;
  // flex-direction: row-reverse;
  align-items: center;
  .searchicon {
    width: 24px;
    // margin-right: 10px;
  }
  .select {
    width: 0;
    overflow: hidden;
    transition: width 0.5s;
    :deep .el-input__inner {
      height: 24px;
      box-shadow: none;
      border-bottom: 1px solid #008000;
      border-radius: 0;
    }
    :deep .el-input__inner:hover,
    :deep .el-input__inner:focus {
      box-shadow: none !important;
    }
    :deep .el-input.is-focus .el-input__inner {
      box-shadow: none !important;
    }
  }
  .showSelect {
    width: 160px;
    margin-right: 10px;
  }
}
</style>
