<template>
  <svg-icon
    :icon="isFull ? 'fullscreen-exit' : 'screenfull'"
    class="iconclass"
    @click="toggleFull"
    id="guide-full"
  ></svg-icon>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import SvgIcon from '@/components/SvgIcon/index.vue'
import screenfull from 'screenfull'
// 是否是全屏
const isFull = ref(false)
// 点击事件的处理函数
const toggleFull = () => {
  // 切换全屏
  screenfull.toggle()
}
// 监听状态改变的回调函数
const change = () => {
  isFull.value = screenfull.isFullscreen
}
// 组件挂载的时候监听
onMounted(() => {
  screenfull.on('change', change)
})
// 组件卸载的时候移除监听
onUnmounted(() => {
  screenfull.off('change', change)
})
</script>

<style lang="scss" scoped>
.iconclass {
  margin-right: 5px;
  width: 24px;
  height: 24px;
}
</style>
