export default {
  login: {
    logo: '螺钉课堂',
    title: '用户登录',
    userLabel: '用户名',
    passLabel: '密码',
    loginBtn: '登录',
    resetBtn: '重置',
    userRule: '用户名不能为空',
    passRule: {
      req: '密码不能为空',
      len: '密码长度6-16位字符'
    }
  },
  // 管理员模块
  admin: {
    username: '用户名',
    mobile: '手机号',
    role: '请选择角色',
    password: '密码'
  },
  // 权限模块
  permission: {
    rights_name: '权限名称',
    rights_desc: '权限简介',
    rights_type: '权限类型',
    rights_url: '请求地址',
    rights_method: '请求方法',
    level: '权限等级',
    pid: '父级权限',
    module: '所属模块',
    menu: '所属菜单'
  },
  //角色模块
  role: {
    role_name: '角色名称',
    role_desc: '角色描述',
    created_at: '创建时间'
  },
  // 文章模块
  article: {
    titleplaceholder: '请输入文章标题'
  },
  course: {
    chapter: '请选择章节',
    chapterCreate: '添加章节',
    courseEdit: '编辑课程',
    courseCreate: '添加课程',
    chapter_name: '章节名称',
    videoCreate: '添加课时',
    category: '请选择分类',
    category_name: '分类名称',
    sort: '排序'
  },
  route: {
    home: '首页',
    profile: '个人中心',
    admin: '管理员',
    adminList: '管理员列表',
    permission: '权限',
    permissionList: '权限列表',
    roleList: '角色列表',
    user: '用户',
    userList: '用户列表',
    userInfo: '用户详情',
    userCv: '用户简历',
    excelImport: 'Excel导入',
    article: '文章',
    articleList: '文章列表',
    articleCreate: '创建文章',
    articleDetail: '文章详情',
    articleEditor: '文章编辑',
    articleCategory: '文章分类',
    course: '课程',
    courseList: '视频课程',
    courseCreate: '添加课程',
    courseCategory: '课程分类',
    courseBook: '电子书',
    addCourseCategory: '添加课程分类',
    editCourseCategory: '编辑课程分类',
    chapterList: '课程章节',
    videoList: '课时列表',
    learnPath: '学习路线',
    learnPathStage: '学习阶段',
    miniCourse: '小课',
    projectCourse: '项目库',
    project: '训练营',
    projectCategory: '项目分类',
    projectList: '项目课程',
    projectCreate: '创建项目课程',
    projectEdit: '编辑项目课程',
    projectCourseList: '项目课时列表',
    systemcourse: '体系课',
    systemPathStage: '体系课阶段',
    systemPathList: '体系课项目',
    Operation: '运营',
    order: '订单',
    setting: '设置',
    vipSetting: 'VIP设置',
    settingWebsite: '网站设置'
  },
  contextMenu: {
    refresh: '刷新',
    closeRight: '关闭右侧',
    closeLeft: '关闭左侧',
    closeOther: '关闭其他',
    closeIndex: '关闭当前'
  },
  guide: {
    tips: '引导页',
    close: '关闭',
    next: '下一步',
    prev: '上一步',
    done: '完成',
    guideTitle: '引导',
    guideDesc: '打开引导功能',
    extendTitle: '菜单伸缩按钮',
    extendDesc: '打开和关闭左侧菜单',
    breadcrumbTitle: '面包屑',
    breadcrumbDesc: '指示当前页面位置',
    searchTitle: '搜索',
    searchDesc: '页面链接搜索',
    fullTitle: '全屏',
    fullDesc: '页面显示切换',
    themeTitle: '主题',
    themeDesc: '更换项目主题',
    langTitle: '国际化',
    langDesc: '语言切换',
    tagTitle: '标签',
    tagDesc: '已打开页面标签',
    sidebarTitle: '菜单',
    sidebarDesc: '项目功能菜单'
  },
  excel: {
    importExcel: 'excel 导入',
    exportExcel: 'excel 导出',
    exportZip: 'zip 导出',
    name: '姓名',
    mobile: '联系方式',
    avatar: '头像',
    role: '角色',
    openTime: '开通时间',
    action: '操作',
    show: '查看',
    showRole: '角色',
    defaultRole: '员工',
    remove: '删除',
    removeSuccess: '删除成功',
    title: '导出为 excel',
    placeholder: 'excel 文件名称',
    defaultName: '员工管理表',
    close: '取 消',
    confirm: '导 出',
    importSuccess: ' 条员工数据导入成功',
    dialogTitle1: '确定要删除用户 ',
    dialogTitle2: ' 吗？',
    roleDialogTitle: '配置角色'
  }
}
