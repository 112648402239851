/**
 *
 * 我们希望工具能帮我们返回下面这种结构的数据
 *[
    {
      path: '/user/userlist',
      title: ['用户', '用户列表'],
    },
    {
      path: '/article/articleCreate',
      title: ['文章', '文章创建'],
    },
  ]
 *
 */
import i18n from '@/lang'
/**
 *
 * @param {*} data  经过处理后的左侧菜单数据
 * @param {*} prefixTitle  title的前缀
 * @returns  返回一个扁平化的数组
 */
export const getFuseData = (data, prefixTitle = []) => {
  let res = []
  data &&
    data.length &&
    data.forEach((item) => {
      //构建一个对象，这个对象就是我们想要的结构
      const obj = {
        path: item.path,
        //i18n.global.t(`msg.route.${item.meta.title}`) 这个是为了添加多语言支持
        title: [...prefixTitle, i18n.global.t(`msg.route.${item.meta.title}`)]
      }
      // 把这个对象放到数组中
      res.push(obj)
      // 如果有子元素并且长度大于0
      if (item.children && item.children.length > 0) {
        // 递归调用 递归调用其实就是重复执行前面的内容，把子元素当参数传进去而已，最后返回的数组拼接到res数组中
        res = [...res, ...getFuseData(item.children, [...obj.title])]
      }
    })
  return res
}
