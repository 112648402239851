import { createStore } from 'vuex'
import sys from './system'
import app from './app'
import permission from '@/store/permission'
import getters from './getters'

export default createStore({
  getters,
  modules: {
    sys,
    app,
    permission
  }
})
