<template>
  <div class="container">
    <ul>
      <li @click="onRefresh">
        {{ $t('msg.contextMenu.refresh') }}
      </li>
      <li @click="onCloseIndex">{{ $t('msg.contextMenu.closeIndex') }}</li>
      <li @click="onCloseRight">{{ $t('msg.contextMenu.closeRight') }}</li>
      <li @click="onCloseLeft">{{ $t('msg.contextMenu.closeLeft') }}</li>
      <li @click="onCloseOther">{{ $t('msg.contextMenu.closeOther') }}</li>
    </ul>
  </div>
</template>

<script setup>
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
const props = defineProps({
  index: {
    type: Number,
    required: true
  },
  modelVue: {
    type: Boolean
  }
})
// 右键刷新
const router = useRouter()
const onRefresh = () => {
  router.go(0)
}
// 右键关闭右侧标签
const store = useStore()
const emit = defineEmits(['update:modelValue'])
const onCloseRight = () => {
  store.commit('app/removeTagsFromList', {
    type: 'right',
    index: props.index
  })
  emit('update:modelValue', false)
}
const onCloseLeft = () => {
  store.commit('app/removeTagsFromList', {
    type: 'left',
    index: props.index
  })
  emit('update:modelValue', false)
}
const onCloseIndex = () => {
  if (store.getters.tagsList.length == 1) {
    ElMessage.success('只有一个标签了，不能关闭了')
    return
  }
  store.commit('app/removeTagsFromList', {
    type: 'index',
    index: props.index
  })
  emit('update:modelValue', false)
  if (store.getters.tagsList[props.index]) {
    router.push(store.getters.tagsList[props.index].fullPath)
    return
  }
  router.push(store.getters.tagsList[props.index - 1].fullPath)
}
// 右键关闭其他标签
const onCloseOther = () => {
  store.commit('app/removeTagsFromList', {
    type: 'other',
    index: props.index
  })
  emit('update:modelValue', false)
}
</script>

<style lang="scss" scoped>
.container {
  position: fixed;
  border-radius: 6px;
  z-index: 999;
  background-color: white;
  width: 100px;
  ul,
  li {
    padding: 0;
    margin: 0;
    width: 100%;
  }
  li {
    list-style: none;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-bottom: 1px solid #e4e7ed;
    font-size: 12px;
    &:hover {
      background-color: #e4e7ed;
    }
  }
}
</style>
