<template>
  <el-breadcrumb separator="/">
    <el-breadcrumb-item :to="{ path: '/' }">Dashboard</el-breadcrumb-item>
    <transition-group name="breadcrumb">
      <el-breadcrumb-item
        class="box"
        v-for="(item, index) in breadcrumbData"
        :key="item.path"
      >
        <span v-if="index == breadcrumbData.length - 1">{{
          $t('msg.route.' + item.meta.title)
        }}</span>
        <a v-else @click.prevent="handleClick(item)">{{
          $t('msg.route.' + item.meta.title)
        }}</a>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
const breadcrumbData = ref([])
const route = useRoute()

const getRouteData = () => {
  breadcrumbData.value = route.matched.filter((item) => {
    return item.meta && item.meta.title
  })
}
const router = useRouter()
const handleClick = (item) => {
  router.push(item.path)
}
watch(
  route,
  () => {
    getRouteData()
  },
  {
    immediate: true
  }
)
</script>

<style lang="scss" scoped></style>
