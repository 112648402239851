<template>
  <div class="container dart">
    <a href="#" class="logo" v-if="$store.getters.isExtend">
      <!-- <img src="~@/assets/img/logo.png" alt="" /> -->
      <svg-icon icon="logo" class="svg-icon" />
      <span>螺钉课堂</span>
    </a>
    <a v-else href="#" class="logo-collapse">
      <svg-icon icon="logo" class="svg-icon" />
    </a>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const logoColor = computed(() =>
  store.getters.theme === 'dart' ? '#fff' : '#000'
)
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';
.container {
  height: 50px;
  text-align: center;
  line-height: 50px;
  .logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: v-bind(logoColor);
    padding-left: 20px;
    img {
      width: 36px;
      height: 36px;
      margin-right: 5px;
    }
    .svg-icon {
      margin-right: 4px;
      font-size: 28px;
    }
  }
  .logo-collapse {
    img {
      width: 32px;
      height: 32px;
      margin-top: 10px;
    }
  }
}
</style>
